.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  box-shadow: 1px 2px 5px lightgray;
  height: 65px;
}

.image {
  height: 40px;
  width: auto;
}

.home {
  padding: 12.5px 16px;
}

.navbar {
  font-family: "Lora", serif;
  font-style: normal;
  margin: 0;
  display: flex;
  gap: 20px;
  align-items: right;
  font-size: 1rem;
  color: #3e3e3e;
  padding-right: 20px;
}

.expandable {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.closebutton {
  padding: 12px;
  border: solid;
  border-width: 1px;
  border-color: #3e3e3e;
  border-radius: 10px;
}

.menubutton {
  padding: 10px;
  border: solid;
  border-width: 1px;
  border-color: #3e3e3e;
  border-radius: 10px;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  right: 20px;
  width: 100px;
  top: 60px;
  padding: 10px;
  position: absolute;
  background-color: #f4f4f4;
  box-shadow: 1px 2px 5px lightgray;
  z-index: 1;
}

@media screen and (max-width: 900px) {
  .nav {
    display: flex;
    flex-direction: row;
  }

  .image {
    height: 40px;
    width: auto;
  }
}