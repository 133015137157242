.question {
  font-size: 1.5vw;
  font-family: "Lora";
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 1.5vw;
  margin-bottom: 1.5vh;
  color: rgb(0, 0, 0);
  font-weight: 30;
}

.answer {
  font-size: 1.5vw;
  font-family: "Lora";
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 1.5vw;
  margin-bottom: 5vh;
  color: rgb(119, 119, 119);
  font-weight: 10;

}
