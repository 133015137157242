.container:hover .dropdowncontent {
  display: block;
}

.mainNav:hover a {
  color: #b27d31;
}

.container {
  position: relative;
  display: inline-block;
}

.dropdowncontent {
  display: none;
  position: absolute;
  border-radius: 15px;
  z-index: 1;
  margin-left: -3vw;
  background-color: #e9e9e9;
}

.dropitems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 1.5vh 20px;
}

.navitem {
  color: #3e3e3e;
  text-decoration: none;
}

.navitem:hover {
  color: #b27d31;
}

@media screen and (max-width: 900px) {
  .container {
    position: relative;
    display: inline-block;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 3px;
  }

  .dropdowncontent {
    display: fixed;
    margin-left: -220px;
    margin-top: -27px;
  }
}
