@font-face {
  font-family: 'Lora';
  src: url('../AboutUs/PresidentMessage/Lora-Regular.ttf') format('truetype');
}

.background {
  background-image: url('./maintenance bg.jpg');
  background-size: cover ; /* Cover the entire viewport */
  background-repeat: no-repeat; /* Prevent repeating the image */
  background-position: center; 
}

.maintenanceHeading {
  font-size: 60px;
  font-family: 'Lora';
  color: #3E3E3E;
}

.maintenanceMessage {
  font-size: 24px;
  font-family: 'Lora';
  color: #3E3E3E;
}

.container {
  width: 60vw; 
  margin: auto;
  padding: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 0 3px #294d77;

}

.button{
  background-color: #294d77;
  color: rgb(169, 68, 68);
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.linkColour {
  color: white;
}
.linkColour:hover, .linkColour:active, .linkColour:visited {
  color: white;
}





