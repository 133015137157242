.header {
    font-size: 5vw;
    font-family: 'Lora', serif;
    margin-top: 6vh; 
    margin-left: 4vw; 
    position: relative;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1); 
    color: #3E3E3E;
}

.subheader {
    font-size: 2.7vw; 
    font-family: 'Lora', serif;
    font-weight: 700;
    color: #D9A129; 
    margin-top: 10vh;
}

.text {
    font-size: 1.4vw; 
    font-family: 'Lora', serif;
    text-align: justify;
    line-height: 1.5;
}

.content {
    margin: 0vh 5vw;
}

.bold {
    font-weight: 2000 !important;
    text-decoration: underline;
}
