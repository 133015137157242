@font-face {
    font-family: 'Lora';
    src: url('./Lora-Regular.ttf') format('truetype');
  }

.Outermost {
    font-family: 'Lora';
    background-color: #F5F5F5;
    color: #3E3E3E;
}  

.Heading1 {
    text-align: center;
    font-size: 80px;
    font-weight: 400;
    color: #3E3E3E;
}

.Events {
    width: 80%;
    margin: auto;
    display: flex; 
    flex-direction: column; /* Align children vertically */
    padding: 60px 60px;  /* 60px top and bottom, 60px left and right */
    
    background-color: #D9D9D9;
    color: #3E3E3E; 
    font-size: 20px;
}

.eventTypeContainer {
    width: 40%;
    background-color: #EFEFEF;
    
}

.textBox {
    font-weight: 400;
    margin: 10px 20px;
    font-size: 20px;
}

.typeList {
    
    width: 100%;
    background-color: #FFFFFF;
    padding-top: 15px;
    display: flex;        /* Use Flexbox for layout */
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
}

.typeList button {
    background: none;
    border: none;
    color: inherit; /* Inherits the color from its parent */
    margin-bottom: 15px; /* Fixed distance between buttons */
    margin-left: 15px;
    font-family: inherit; /* Inherits the font from its parent */
    font-size: inherit; /* Inherits the font size from its parent */
    cursor: pointer; /* Changes the cursor to signify it's clickable */
    outline: none; /* Optional: be cautious with accessibility */
    
}

.eventContents {
    display: flex;
}

