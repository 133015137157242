.announcement {
  color: #3e3e3e;
  background-color: rgb(178, 125, 49, 0.5);
  padding: 0.5vh 4vw;
  font-size: 1rem;
  font-family: 'Lora', serif;
  color: #3E3E3E;
  -webkit-text-stroke: 1px black;
  text-shadow: 2px 2px #82828240;
}

.header {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 40% 60%;
  background-color: #d9d9d9;
}

.quote {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 5.5vw;
  padding-left: 4.5vw;
  font-family: 'Lora', serif;
}

.image {
  width: 40vw;
  padding-left: 10vw;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 70px;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  flex-direction: column;
}

.events {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3vh 0;
  font-family: 'Inter', sans-serif;
}

.slider {
  object-fit: contain;
}

.eventsheader {
  color: #3e3e3e;
  font-size: 4vw;
  margin-bottom: 20px;
}

.socialheader {
  margin-top: 5vh;
  font-size: 3.5rem;
  text-decoration: underline;
  text-decoration-color: black;
  text-decoration-thickness: 1px;
  text-underline-position: under;
  padding: 5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.odd {
  color: #3e3e3e !important;
}

.even {
  color: #d9a129 !important;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

@media screen and (max-width: 850px) {

  .container {
    flex-direction: column;
  }

  .eventsheader {
    font-size: 40px;
  }
  .socialheader {
    font-size: 35px;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  .announcement {
    font-size: 2.6vw;
  }

}
