.form {
    max-width: 75vw;
    margin: 50px auto;
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Lora', serif;
}

.form-group {
    margin-bottom: 20px;
}

.label {
    display: block;
    margin-bottom: 8px;
    color: #555555;
    font-size: 1vw;
    font-weight: bold;
}

.input,
.textarea {
    width: 70vw;
    padding: 12px 15px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    color: #333333;
    font-size: 1vw;
    transition: border-color 0.3s ease-in-out;
}

.input:focus,
.textarea:focus {
    border-color: #007bff;
    outline: none;
}

.textarea {
    height: 120px;
    resize: vertical;
}

.button {
    display: inline-block;
    width: 25vw;
    padding: 12px 20px;
    border-radius: 6px;
    border: none;
    background-color: #007bff;
    color: #ffffff;
    font-size: 1.2vw;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-family: 'Lora', serif;
}

.button:hover {
    background-color: #0056b3;
}


.map {
    width: '100%';
    height: '400px';
}

.mapImage {
    width: 60vw;
    height: 60vh;
}

.headingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10vh;
}

.heading {
    font-size: 5vw;
    font-family: 'Lora', serif;
    margin-top: 6vh; 
    margin-left: 4vw; 
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1); 
    color: #3E3E3E;
}

.horizontalLine {
    width: 80%; 
    max-width: 25vw;
    height: 0.2vh;
    background-color: #000;
    margin-bottom: 7vh; 
    margin-left: 6vw;
}

.contactInfo {
    margin-left: 5vw;
    margin-bottom: 10vh;
}

.subheader {
    font-size: 2.7vw; 
    font-family: 'Lora', serif;
    font-weight: 700;
    color: #D9A129; 
}

.contactheader {
    font-size: 1.7vw; 
    font-family: 'Lora', serif;
    font-weight: bolder;
    color: #3e3e3e; 
}

.text {
    font-size: 1.4vw; 
    font-family: 'Lora', serif;
    text-align: justify;
    line-height: 1.5;
    margin-bottom: 5vh;
    color: #3e3e3e
}

.emailheader {
    font-size: 2.7vw; 
    font-family: 'Lora', serif;
    font-weight: 700;
    color: #D9A129; 
    margin-left: 5vw;
}
.notification {
    background-color: #FFD700; /* Yellow color */
    color: #333;
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
    font-weight: bold;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #FF9800;
  }
  
  .input, .textarea {
    padding: 10px;
    border: 2px solid #FFB74D;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
  }
  
  .input:focus, .textarea:focus {
    border-color: #FFA726;
  }
  
  .button {
    padding: 12px;
    background-color: #FF9800;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #FFB74D;
  }
  