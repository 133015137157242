.header {
    font-size: 5vw;
    font-family: 'Lora', serif;
    margin-top: 6vh; 
    margin-left: 4vw; 
    position: relative;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1); 
    color: #3E3E3E;
}

.subheader {
    font-size: 2.7vw; 
    font-family: 'Lora', serif;
    font-weight: 700;
    color: #D9A129; 
}

.content {
    margin: 0vh 5vw;
}

.text {
    font-size: 1.4vw; 
    font-family: 'Lora', serif;
    text-align: justify;
    line-height: 1.5;
    margin-bottom: 2.5vh;
}

.horizontalLine {
    max-width: 50vw;
    height: 0.2vh;
    background-color: #000;
    margin-top: 1vw; 
    margin-bottom: 1.5vh; 
    margin-left: 0vw;
}

.table {
    display: flex;
    justify-content: space-evenly;
}

.tableCol {
    display: flex;
    flex-direction: column;
}

.applicationPeriod {
    flex: 1;
    padding-right: 1vw;
}

.applicationLink {
    flex: 1;
    padding-left: 1vw;
}

.icons {
    display: flex;
    margin-top: 2.5vh;
}

.icon {
    margin: 0 2.5vw;
}